


























































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  ResponseListOfRole,
  ResponseListOfMenus,
  CreateMenuRole,
} from "@/models/interface/user.interface";
import { Messages } from "@/models/enums/messages.enum";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { getauthorities } from "@/helpers/cookie";
import { userServices } from "@/services/user.services";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";

@Component({
  mixins: [MNotificationVue],
})
export default class ListRole extends Vue {
  authPrivilege = [] as string[];
  loadingTable = false as boolean;
  dataDetail = "" as string;
  dataListRole = {} as ResponseListOfRole;
  dataListMenu = {} as ResponseListOfMenus;
  typemodal = "" as string;
  isFormSubmitted = false as boolean;
  selectedRowKeysModal = [] as number[];
  page = 0 as number;
  limit = 10 as number;
  search = "" as string;
  sort = "" as string;
  trigerdisable = false as boolean;
  titlemodalOpen = "" as string;
  direction = "" as string;
  pageSizeSet = 10;
  payloadPagination = {};
  defaultPagination = false as boolean;
  modalOpen = false as boolean;
  form = this.$form.createForm(this, { name: "addMenuForm" });
  tempMenuid = [] as string[];
  optionModal = [] as string[];
  columnsTable = [
    {
      title: this.$root.$t("lbl_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 200,
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      width: 120,
      align: "center",
      scopedSlots: { customRender: "operation" },
      fixed: "right",
      button: ["update"],
    },
  ] as ColumnTableCustom[];
  columnsTableModal = [
    {
      title: this.$root.$t("lbl_menu"),
      dataIndex: "menu",
      key: "menu",
      width: "20%",
      scopedSlots: { customRender: "menu" },
      responsiveColCheckBox: ["create", "read", "update", "delete"],
      responsiveColSelect: [
        {
          name: "menu",
          placeholder: this.$root.$t("lbl_menu"),
          style: "width: 100%;",
          value: "id",
          options: [] as any[],
        },
      ],
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
    },
    {
      title: this.$root.$t("lbl_create"),
      dataIndex: "create",
      key: "create",
      width: "15%",
      scopedSlots: { customRender: "create" },
      align: "center",
    },
    {
      title: this.$root.$t("lbl_read"),
      dataIndex: "read",
      key: "read",
      width: "12%",
      scopedSlots: { customRender: "read" },
      align: "center",
    },
    {
      title: this.$root.$t("lbl_edit"),
      dataIndex: "update",
      key: "update",
      width: "12%",
      scopedSlots: { customRender: "update" },
      align: "center",
    },
    {
      title: this.$root.$t("lbl_delete"),
      dataIndex: "delete",
      key: "delete",
      width: "12%",
      scopedSlots: { customRender: "delete" },
      align: "center",
    },
  ] as any[];
  dataSourceModal = [] as CreateMenuRole[];
  formRules = {
    name: {
      label: "lbl_name",
      name: "name",
      placeholder: "lbl_name_placeholder",
      decorator: [
        "name",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: ["description", {}],
    },
  };
  mounted() {
    this.getListOfRole();
  }
  showConfirmation() {
    if (this.selectedRowKeysModal.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeysModal.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  handleCLickFLoating() {
    this.createNew();
  }
  ResetFilter(getlist: boolean) {
    this.direction = "";
    this.search = "";
    if (getlist) this.getListOfRole();
  }
  reponseDeleteTable(response) {
    const path = "/" + response.data.id;
    userServices.deleteRole(path).then(res => {
      if (res) {
        this.ResetFilter(false);
        this.getListOfRole();
      }
    });
  }
  reponseEditTable(response) {
    this.trigerdisable = false;
    this.typemodal = "edit";
    this.titlemodalOpen = "lbl_edit_role";
    this.ResetFilter(false);
    this.resetCheckbox();
    this.getListMenu("", "");
    this.getDetailRole(response.data.id);
    this.dataDetail = "/" + response.data.id;
  }
  handleSearchSelectTable(value, _key, _col) {
    this.getListMenu("", value);
  }
  getListMenu(path, value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "name:asc",
    } as RequestQueryParamsModel;
    if (value) params.search = `name~*${value}*_OR_description~*${value}*`;
    userServices.listOfMenus(params, path).then((res: ResponseListOfMenus) => {
      res.data.map((dataObject, index) => (dataObject.key = index));
      this.dataListMenu = res;
      const findColumn = this.columnsTableModal.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      findColumn[0].options = res.data;
      this.modalOpen = true;
    });
  }
  handleSelectTableModal(value, _key, _col, recordOptions) {
    this.dataSourceModal[_key] = {
      ...this.dataSourceModal[_key],
      menu: value,
      create: recordOptions.find(data => data.id === value)
        ? recordOptions.find(data => data.id === value).create
        : false,
      read: recordOptions.find(data => data.id === value)
        ? recordOptions.find(data => data.id === value).read
        : false,
      update: recordOptions.find(data => data.id === value)
        ? recordOptions.find(data => data.id === value).update
        : false,
      delete: recordOptions.find(data => data.id === value)
        ? recordOptions.find(data => data.id === value).delete
        : false,
      id: value,
      disabledSelect: false,
      disabledCheckBox: true,
    };
    const option = recordOptions.find(data => data.id === value);
    this.dataSourceModal[_key] = {
      ...this.dataSourceModal[_key],
      description: option?.description,
    };
    this.dataSourceModal = this.dataSourceModal.slice();
  }
  resetCheckbox() {
    this.selectedRowKeysModal = [];
  }
  onSelectChangeModal(selectedRowKeysModal) {
    this.selectedRowKeysModal = selectedRowKeysModal;
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.getListOfRole();
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.getListOfRole();
  }
  reponseSearchInput(response) {
    if (response)
      this.search = "name~*" + response + "*_OR_description~" + response + "*";
    else this.search = "";
    this.getListOfRole();
  }
  createNew() {
    this.resetCheckbox();
    this.form.resetFields();
    this.dataSourceModal = [];
    this.trigerdisable = false;
    this.typemodal = "create";
    this.titlemodalOpen = "lbl_create_new_role";
    this.ResetFilter(false);
    this.getListMenu("", "");
  }

  handleCancel() {
    this.modalOpen = false;
  }
  onSorterChange(response) {
    if (response.sort.order) {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
    } else {
      this.ResetFilter(false);
    }
    this.getListOfRole();
  }
  getListOfRole() {
    let params;
    params = {
      limit: this.limit,
      page: this.page,
    } as RequestQueryParamsModel;
    if (this.search) params.search = this.search;
    if (this.direction) params.sorts = this.sort;
    this.loadingTable = true;
    userServices
      .listOfRole(params)
      .then((res: ResponseListOfRole) => {
        res.data.forEach((dataObject, index) => (dataObject.key = index));
        this.dataListRole = res;
      })
      .finally(() => (this.loadingTable = false));
  }

  getDetailRole(id: string): void {
    userServices
      .detailRole(id)
      .then(res => {
        const values = {
          name: res.name,
          description: res.description ?? "-",
        };
        for (const key in values) {
          this.form.getFieldDecorator(key, { initialValue: values[key] });
        }
        this.dataSourceModal = res.menus.map((item, idx) => ({
          ...item,
          key: idx,
          disabledCheckBox: true,
          disabledSelect: false,
          description: item.description ?? "-",
          menu: item.name,
        }));
      })
      .finally(() => (this.loadingTable = false));
  }

  handleAddRow() {
    const dataColumn: CreateMenuRole = {
      menu: "",
      description: "",
      id: "",
      create: false,
      read: false,
      update: false,
      delete: false,
      key: this.dataSourceModal.length,
      disabledSelect: false,
      disabledCheckBox: false,
      name: "",
    };
    this.dataSourceModal = [...this.dataSourceModal, dataColumn];
  }
  handleDeleteRow() {
    this.dataSourceModal = this.dataSourceModal.filter(data => {
      return !this.selectedRowKeysModal.includes(data.key);
    });
    this.dataSourceModal.forEach((data, index) => (data.key = index));
    this.selectedRowKeysModal = [];
  }
  submitForm(e, type) {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        this.tempMenuid = [];
        this.dataSourceModal.forEach(item => {
          if (item.id) {
            this.tempMenuid.push(item.id);
          }
        });
        const datapost = {
          name: values.name,
          description: values.description,
          menuIds: this.tempMenuid,
        };
        this.isFormSubmitted = true;
        switch (type) {
          case "create":
            userServices
              .createRole(datapost)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfRole();
                  this.form.resetFields();
                  this.showNotifSuccess("notif_create_success");
                }
              })
              .catch(() => {
                this.isFormSubmitted = false;
                this.showNotifError("notif_create_fail");
              })
              .finally(() => (this.isFormSubmitted = false));
            break;
          case "edit":
            userServices
              .updateRole(datapost, this.dataDetail)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfRole();
                  this.form.resetFields();
                  this.showNotifSuccess("notif_update_success");
                }
              })
              .catch(() => {
                this.isFormSubmitted = false;
                this.showNotifError("notif_update_fail");
              })

              .finally(() => (this.isFormSubmitted = false));
            break;
          default:
            break;
        }
      } else {
        this.showNotifError("lbl_validation_required_error");
      }
    });
  }
}
